<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain type-lhc twlh game-twlh game-lhc-slx">
        <div class="data ctop">
          <h3>自选不中</h3>
          <ul class="datatop" v-for="item in rowsData" :key="item.title">
            <li v-for="(ele,index) in item.arr" @click="setActiveIndex(index+5)" :class="bzIndex==ele.num?'selected':''" :key="ele.ResultID" style="width: 12.5%">
              <ol class="td_name">
                {{ele.label}}
              </ol>
              <ol class="td_rate">
                {{ele.Odds}}
              </ol>
            </li>
          </ul>
        </div>
        <div class="data">
          <ul>
            <li :class="item.selected ? 'selected' : ''" v-for="(item, index) in numArr" :key="index" @click="setCurrentSelected(item)">
              <ol class="td_name">
                <div :class="item.color + 'ball b' + (index + 1)">
                  {{ index + 1 }}
                </div>
              </ol>
              <!-- <ol style="width: 100px" class="td_chkbox">
                <input @click.stop="checkClick($event,item)" :checked="item.selected" type="checkbox" />
              </ol> -->
            </li>
            <li>
              <ol class="td_name"></ol>

            </li>
          </ul>
        </div>

      </div>
      <div class="player listmain tuoduopeng">
        <div class="data">
          <h3>注单号码 <span>共 <em class="red">{{totalNum}}</em> 种组合 </span></h3>

          <div class="dantuo-sc red">
            <div>
              <span v-for="(item,index) in zuheData" :key="index">[{{item.join(',')}}]</span>
            </div>
          </div>
        </div>
      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zixuanbuzhongData } from "../data";

import mixins from "../mixins/mainMixins";

/**
 * 求：组合C(m, n)，m为上标，n为下标。m选n的所有项
 * m {必传} 原始数据
 * n {必传} 当前项还需元素的个数
 * currentIndex 当前索引
 * choseArr 当前项的部分元素集合（不是完整项，是生成完整项的一个中间状态）
 * result 所有项的结果结合
 */
function cmn(m, n, currentIndex = 0, choseArr = [], result = []) {
  let mLen = m.length;
  // 可选数量小于项所需元素的个数，则递归终止
  if (currentIndex + n > mLen) {
    return;
  }
  for (let i = currentIndex; i < mLen; i++) {
    // n === 1的时候，说明choseArr在添加一个元素，就能生成一个新的完整项了。
    // debugger
    if (n === 1) {
      // 再增加一个元素就能生成一个完整项，再加入到结果集合中
      result.push([...choseArr, m[i]]);
      // 继续下一个元素生成一个新的完整项
      i + 1 < mLen && cmn(m, n, i + 1, choseArr, result);
      break;
    }
    // 执行到这，说明n > 2，choseArr还需要两个以上的元素，才能生成一个新的完整项。则递归，往choseArr添加元素
    cmn(m, n - 1, i + 1, [...choseArr, m[i]], result);
  }
  return result;
}

export default {
  name: "zixuanbuzhong",
  components: { topTimer, yushe, yanzheng },
  props: [""],
  data() {
    return {
      groupnames:'zxbz',
      totalNum: 0,
      zuheData: [],
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [{ title: "自选不中", arr: [] }],
      labelArr: [
        "五不中",
        "六不中",
        "七不中",
        "八不中",
        "九不中",
        "十不中",
        "十一不中",
      ],
      bzIndex: 5,
      numArr: [],
    };
  },
  mixins: [mixins],
  watch: {
    amount(val) {
      this.watchData();
    },
    numArr: {
      immediate: true,
      deep: true,
      handler(numarr) {
        this.watchData();
      },
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let arr = this.filterData(resAArr, 2041, 2047, this.labelArr);
        // 十二不中
        let arr1 = this.ddFilterData1(resAArr, [3967], ["十二不中"]);
        let arr2 = arr.concat(arr1);
        arr2.forEach((item, index) => {
          item.num = index + 5;
        });
        this.rowsData[0].arr = arr2;
        // console.log(this.rowsData, "rowDataaaaa");
      },
    },
  },

  created() {
    zixuanbuzhongData.forEach((item, index) => {
      item.label = this.checkNum(index + 1);
      item.b = false;
      item.selected = false;
    });
    this.numArr = zixuanbuzhongData;
    // console.log(this.numArr, "numarr");
  },

  mounted() {},

  methods: {
    watchData() {
      let arr = this.numArr.filter((item) => item.selected);
      let selArr = [];
      let len = arr.length;
      if (len < this.bzIndex) {
        this.$store.commit("setXZList", []);
        this.totalNum = 0;
        this.zuheData = [];
      } else {
        this.totalNum = 0;
        this.zuheData = [];
        arr.forEach((item) => {
          selArr.push(item.label);
        });
        let item = this.rowsData[0].arr.find(
          (item) => this.bzIndex == item.num
        );
        if (len == this.bzIndex) {
          this.totalNum = 1;
          this.zuheData = [selArr];
          let obj = {
            label: selArr.join(","),
            num: selArr.join(","),
            title: item.label,
            id: item.ResultID,
            money: this.amount,
            Odds: item.Odds,
          };
          this.$store.commit("setXZList", [obj]);
        } else {
          let xiazhu = [];
          let cArr = cmn(selArr, this.bzIndex);
          cArr.forEach((ele) => {
            this.totalNum++;
            this.zuheData.push(ele);
            let obj = {
              label: ele.join(","),
              num: ele.join(","),
              title: item.label,
              id: item.ResultID,
              money: this.amount,
              Odds: item.Odds,
            };
            xiazhu.push(obj);
          });
          this.$store.commit("setXZList", xiazhu);
        }
      }
    },
    checkNum(num) {
      if (num < 10) {
        num = "0" + num;
      }
      return "" + num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain > .data > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
  min-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  cursor: pointer;
}

.listmain .ctop .datatop li {
  display: inline-block;
}
.listmain div.ctop .datatop li ol {
  width: auto !important;
  max-width: none !important;
  display: block;
  border-right: none !important;
}

.listmain > .data > ul > li .td_name {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #4f4d4d;
}
</style>
